import { render, staticRenderFns } from "./BaseLayout.vue?vue&type=template&id=655f153c&scoped=true&"
import script from "./BaseLayout.vue?vue&type=script&lang=js&"
export * from "./BaseLayout.vue?vue&type=script&lang=js&"
import style0 from "./BaseLayout.vue?vue&type=style&index=0&id=655f153c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "655f153c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Advertisement: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/Advertisement/Advertisement.vue').default,TheNewHeader: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheNewHeader/TheNewHeader.vue').default,TheBreadcrumb: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheBreadcrumb.vue').default,NewsletterModal: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/account/NewsletterModal/NewsletterModal.vue').default,TheDisclaimer: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheDisclaimer.vue').default,TheFooter: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheFooter/TheFooter.vue').default,ReportWordDialog: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/common/ReportWordDialog/ReportWordDialog.vue').default})
