import facebook from "@/assets/images/socials/facebook.svg";
import x from "@/assets/images/socials/ic-x.svg";
import instagram from "@/assets/images/socials/instagram.svg";
import ImageItem from "~/components/LazyImage/LazyImage";

export default {
	name: "TheFooter",
	components: { ImageItem },
	data() {
		return {
			currentYear: new Date().getFullYear(),
			footerLinks: [
				{
					linkDictionaryKey: "FooterAboutUrl",
					labelDictionaryKey: "FooterAboutText",
				},
				{
					linkDictionaryKey: "FooterAdvertisersUrl",
					labelDictionaryKey: "FooterAdvertisersText",
				},
				{
					linkDictionaryKey: "FooterContactUsUrl",
					labelDictionaryKey: "FooterContactUsText",
				},
				{
					linkDictionaryKey: "FooterPrivacyPolicyUrl",
					labelDictionaryKey: "FooterPrivacyPolicyText",
				},
				{
					linkDictionaryKey: "FooterTermsOfUseUrl",
					labelDictionaryKey: "FooterTermsOfUseText",
				},
				{
					linkDictionaryKey: "FooterSuggestionsUrl",
					labelDictionaryKey: "FooterSuggestionBoxText",
				},
			],
			socials: [
				{
					link: "https://www.facebook.com/WordFinderbyYD/",
					icon: facebook,
					title: "Follow WordFinder on Facebook",
					aria: "Facebook",
				},
				{
					link: "https://x.com/wordfinderbyyd",
					icon: x,
					title: "Follow WordFinder on X",
					aria: "X",
				},
				{
					link: "https://www.instagram.com/wordfinderbyyd/",
					icon: instagram,
					title: "Follow WordFinder on Instagram",
					aria: "Instagram",
				},
			],
			helpers: [
				{
					link: "https://wordscapes.yourdictionary.com/",
					linkTitle: "Wordscapes® Answers & Cheat",
					linkText: "Wordscapes Answers & Cheat",
					iconPath: "/static/images/ic_ft_ws.png",
					iconAlt: "Wordscapes logo",
					iconWidth: 26,
					iconHeight: 26,
				},
				{
					link: "https://crosswordhelper.com/",
					linkTitle: "Crossword Helper",
					linkText: "Crossword Helper",
					iconPath: "/static/images/ic_ft_cwh.png",
					iconAlt: "Crossword Helper logo",
					iconWidth: 24,
					iconHeight: 24,
				},
				{
					link: "https://wordlistfinder.com/",
					linkTitle: "WordList Finder",
					linkText: "WordList Finder",
					iconPath: "/static/images/ic_ft_wlf.png",
					iconAlt: "WordList Finder logo",
					iconWidth: 24,
					iconHeight: 24,
				},
				{
					link: "https://es.yourdictionary.com/buscador-de-palabras/",
					linkTitle: "Buscador de palabras",
					linkText: "Buscador de palabras",
					iconPath: "/static/images/ic_wfw_header.png",
					iconAlt: "Buscador de palabras logo",
					iconWidth: 35,
					iconHeight: 22,
				},
				{
					link: "https://www.yourdictionary.com/",
					linkTitle: "YourDictionary",
					linkText: "YourDictionary",
					iconPath: "/static/images/ic_ft_yd.png",
					iconAlt: "YourDictionary logo",
					iconWidth: 26,
					iconHeight: 26,
				},
			],
			appLinkMobile: "https://wordfinder.page.link/app",
			appLinkIOS: "https://wordfinder.onelink.me/NEup/620a4a5f",
			appLinkAndroid: "https://wordfinder.onelink.me/NEup/42ae21b7",
		};
	},
	computed: {
		isHomePage() {
			return this.$store.getters["navigation/isHomePage"];
		},
	},
	methods: {
		onAppLinkClick(type, id) {
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "App Badges",
				eventAction: `Click on ${type} footer badge`,
				eventLabel: `${type} Footer Badge`,
				eventID: id,
			});
		},
		getAppLink(type) {
			if (type === "Android") {
				return this.appLinkAndroid;
			}

			if (type === "iOS") {
				return this.appLinkIOS;
			}

			// Return this is a default, just in case.
			return this.appLinkMobile;
		},
		onOneTrustLinkClick(event) {
			const OTLink = document.getElementById("ot-sdk-btn");
			if (OTLink) {
				OTLink.click();
			}
		},
	},
};
