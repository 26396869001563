import ResizeObserver from "resize-observer-polyfill";
import {
	getAdPageType,
	getAdPlacement,
	getAdPosition,
	getPageKeyValue,
	getAditudeBaseId,
} from "@utils";
import { AD_PLACEMENTS, AD_LAYOUT_STUBS, AD_POSITIONS } from "@consts";
import { getBucketId } from "@/common/feature-toggling";

/**
 * ADVERTISING
 *
 * Advertising Component contains and displays an Ad Slot. It REQUIRES an `id`,
 * it always provides `wordfinder-ad` class and it ALLOWS `sticky` class.
 *
 * `sticky` - Will make the Ad slot sticky under the header.
 *
 */
export default {
	name: "Advertising",
	props: {
		keyValues: {
			type: Object,
			required: false,
			default: null,
		},
		stub: {
			type: String,
			required: true,
		},
		isSticky: {
			type: Boolean,
			default: false,
		},
		adId: {
			type: String,
			required: false,
		},
		pageType: {
			type: String,
			required: false,
		},
		placement: {
			type: String,
			required: false,
		},
		position: {
			type: String,
			required: false,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			observer: null,
		};
	},
	computed: {
		outerElementAdClasses() {
			const classes = ["ad"];
			classes.push(`ad--${this.stub?.toLowerCase()?.replace(/_/g, "-")}`);

			if (this.isSticky) {
				classes.push("sticky");
			}

			if (this.isExcoAd) {
				classes.push("advertising__exco");
			}

			return classes;
		},
		innerElementAdClasses() {
			const classes = ["ad__inner"];

			if (this.stub === AD_LAYOUT_STUBS.MOB_IN_CONTENT) {
				classes.push("ad__inner--in-content");
			}

			if (this.stub === AD_LAYOUT_STUBS.RIGHT_RAIL) {
				classes.push("text-center");
			}

			if (this.innerElementIsScrollable) {
				classes.push("sticky");
			}

			return classes;
		},
		isDebugEnabled() {
			return (
				this.isFeatureEnabled("DebugMode") &&
				this.isFeatureEnabled("DebugAds")
			);
		},
		debugDataAsHTML() {
			const data = { ...this.targetingData };

			if (this.isExcoAd) {
				data.Type = "Exco";
			} else {
				data.Type = "Aditude";
				data["Aditude ID"] = this.aditudeId;
				data["Aditude BaseID"] = this.aditudeBaseId;
			}

			if (this.isSmallDevice) {
				return Object.entries(data)
					.map(([key, value]) => `${key}: <strong>${value}</strong>`)
					.join(", ");
			}

			return Object.entries(data)
				.map(([key, value]) => `${key}: <strong>${value}</strong>`)
				.join("<br />");
		},
		stickyTop() {
			const GAP_BELOW_HEADER = 22;
			return (
				GAP_BELOW_HEADER +
				this.$store.getters["window/headerClearanceHeight"]
			);
		},
		innerElementIsScrollable() {
			switch (this.stub) {
				case AD_LAYOUT_STUBS.ADHESION:
				case AD_LAYOUT_STUBS.TOP_LEADERBOARD:
					return false;
				default:
					return true;
			}
		},
		_pageType() {
			if (typeof this.pageType === "string" && this.pageType.length) {
				return this.pageType;
			}

			return getAdPageType(this.$route.name);
		},
		_placement() {
			if (typeof this.placement === "string" && this.placement.length) {
				return this.placement;
			}

			return getAdPlacement(this.stub);
		},
		_position() {
			if (typeof this.position === "string" && this.position.length) {
				return this.position;
			}

			return getAdPosition(this._placement);
		},
		aditudeBaseId() {
			if (this.isResultsPage) {
				if (this._placement === AD_PLACEMENTS.DT_IN_CONTENT_1) {
					return "pb-slot-dt-incontent-1-resultsUnscramble";
				} else if (this._placement === AD_PLACEMENTS.MOB_IN_CONTENT_1) {
					return "pb-slot-mob-in-content-1-resultsUnscramble";
				}
			}
			return getAditudeBaseId(this._placement);
		},
		aditudeId() {
			if (typeof this.adId === "string" && this.adId.length) {
				return this.adId;
			}

			const stubs = [this._placement];
			if (this.$route.name && !this.isPersistentAd) {
				stubs.push(this.$route.name);
			}
			return stubs.join("_");
		},
		excoPlayerId() {
			return process.env.NUXT_ENV_STAGE === "prod"
				? "722bf882-df54-4b64-a54f-b86c95d9e200"
				: "e5d6defa-1679-4434-9a2c-e467f66697f3";
		},
		isPersistentAd() {
			return [
				AD_LAYOUT_STUBS.ADHESION,
				AD_LAYOUT_STUBS.TOP_LEADERBOARD,
			].includes(this.stub);
		},
		bucketValue() {
			return getBucketId();
		},
		targetingData() {
			const data = {};

			if (this._pageType) {
				data.pagetype = this._pageType;
			}
			if (this._placement) {
				data.placement = this._placement;
			}
			if (this._position) {
				data.position = this._position;
			}
			if (this.bucketValue) {
				data.bucket_value = this.bucketValue;
			}

			data.PV_Count = this.$store.state.tracking.pageViewCounter;

			const pageKeyValue = getPageKeyValue(this.$route.name);
			if (typeof pageKeyValue === "string" && pageKeyValue.length) {
				data["WFW-Page"] = pageKeyValue;
			}

			data.environment = "prod";
			if (this.isNuxtClient && window?.location.origin) {
				const origin = window?.location.origin || "";
				if (
					origin.includes("wordfinder-stg") ||
					origin.includes("wordfinder-nuxt-stg")
				) {
					data.environment = "stg";
				} else if (origin.includes("wordfinder-qa")) {
					data.environment = "qa";
				}
			}

			// Add revenue demographic tracking.
			if (this.isNuxtClient) {
				const trackerString = window.revTracker?.getTrackerString?.();
				if (trackerString) {
					data.seo_experiment = trackerString;
				}
			}

			return data;
		},
		isResultsPage() {
			return this.$store.getters["navigation/isResultsPage"];
		},
		shouldShowTag() {
			return (
				(this._position === AD_POSITIONS.RIGHT ||
					this._position === AD_POSITIONS.IN_CONTENT) &&
				!this.isExcoAd
			);
		},
		hasExcoFlag() {
			return (
				(this.isFeatureEnabled("ExcoMobile") && this.isSmallDevice) ||
				(this.isFeatureEnabled("ExcoDesktop") && !this.isSmallDevice)
			);
		},
		isExcoAd() {
			const userLocation = this.$store.state?.account?.location || "";
			const isExcoLocation = ["US", "CA", "GB"].includes(userLocation);

			return (
				isExcoLocation &&
				this.hasExcoFlag &&
				this.$store.getters["navigation/isResultsPage"] &&
				this.isFirstInContentAd
			);
		},
		isFirstInContentAd() {
			return [
				AD_PLACEMENTS.MOB_IN_CONTENT_1,
				AD_PLACEMENTS.DT_IN_CONTENT_1,
			].includes(this._placement);
		},
	},
	methods: {
		setTopAdHeight() {
			const parentHeight = this.$el?.parentElement?.clientHeight;
			if (parentHeight) {
				this.$store.commit(
					"window/setTopAdHeight",
					this.$el.parentElement.clientHeight
				);
			}
		},
		refreshAditudeAd() {
			window.tude = window.tude || { cmd: [] };
			window?.tude.cmd.push(() => {
				if (this.isDebugEnabled) {
					// eslint-disable-next-line no-console
					console.debug("ADITUDE CMD > REFRESH", this.aditudeId);
				}
				window?.tude.refreshAdsViaDivMappings([
					{
						divId: this.aditudeId,
						baseDivId: this.aditudeBaseId,
						targeting: this.targetingData,
					},
				]);
			});
		},
	},
	mounted() {
		if (
			this.stub === AD_LAYOUT_STUBS.TOP_LEADERBOARD &&
			this._placement === AD_PLACEMENTS.DT_TOP
		) {
			this.observer = new ResizeObserver(this.setTopAdHeight).observe(
				this.$el
			);
		}

		if (!this.loading && window) {
			if (this.isDebugEnabled) {
				// eslint-disable-next-line no-console
				console.debug(
					`ADITUDE LOG > Loading Aditude ad with div ID ${this.aditudeId} and base div ID ${this.aditudeBaseId}.`
				);
			}
			this.refreshAditudeAd();
		}
	},
	beforeDestroy() {
		if (!this.loading) {
			if (this.isDebugEnabled) {
				// eslint-disable-next-line no-console
				console.debug(
					`ADITUDE LOG > About to destroy the Aditude ad with div ID ${this.aditudeId} with base div ID ${this.aditudeBaseId}.`
				);
			}
			window?.tude.cmd.push(() => {
				if (this.isDebugEnabled) {
					// eslint-disable-next-line no-console
					console.debug("ADITUDE CMD > DESTROY", this.aditudeId);
				}
				window.tude.destroyAds([this.aditudeId], false);
			});
		}
	},
	watch: {
		$route(to, from) {
			if (
				this.isPersistentAd &&
				from.name &&
				from.fullPath !== to.fullPath
			) {
				this.refreshAditudeAd();
			}
		},
	},
};
