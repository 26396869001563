
export default {
	props: {
		playerId: {
			type: String,
			required: true,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			playerStarted: false,
		};
	},
	computed: {
		isExcoScriptLoaded() {
			return this.$store.state.ads.isExcoLoaded;
		},
	},
	watch: {
		isExcoScriptLoaded(value) {
			if (value) {
				this.initExcoPlayer();
			}
		},
	},
	mounted() {
		if (!this.playerStarted) {
			this.initExcoPlayer();
		}
	},
	beforeDestroy() {
		if (window.ExCoPlayer) {
			const excoAPI = window.ExCoPlayer?.connect?.();
			if (excoAPI) {
				try {
					excoAPI?.destroy?.();
				} catch (error) {
					console.error(
						`Error while trying to destroy Exco ad ${error}`
					);
				}
			}
		}
	},
	methods: {
		initExcoPlayer() {
			if (window.ExCoPlayer && !this.loading) {
				const excoAPI = window.ExCoPlayer?.connect?.();
				if (excoAPI) {
					excoAPI?.init?.();
					this.playerStarted = true;
				}
			}
		},
	},
};
