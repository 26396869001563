// @ts-check

import { clamp } from "@lovetoknow/love-utils-js";
import {
	PAGES,
	PRODUCTION_ORIGIN,
	WORD_LIST_LIMITS,
	SEARCH_GAMES,
} from "@consts";
import {
	sanitizeTiles,
	isEmpty,
	getResultsUrl,
	buildWordListPathFromFields,
} from "@utils";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(tz);

export const isLandingPage = (routeName) => {
	return [
		PAGES.HOME,
		PAGES.WWF,
		PAGES.UNSCRAMBLE,
		PAGES.WORD_DESCRAMBLER,
		PAGES.SCRABBLE_CHEAT,
		PAGES.SCRABBLE_DICT,
		PAGES.SCRABBLE_CHECKER,
		PAGES.WORDFEUD,
		PAGES.WORD_CONNECT,
		PAGES.WORDBRAIN_ANSWERS,
		PAGES.WORD_STORY,
		PAGES.WORD_CHUMS_CHEAT,
		PAGES.ANAGRAM,
		PAGES.FOURPICS,
		PAGES.JUMBLE_SOLVER,
		PAGES.LETTERS_AND_BLANK,
		PAGES.WORDCOOKIES,
		PAGES.WORD_SCRAMBLE,
		PAGES.WORD_MAKER,
		PAGES.TEXT_TWIST,
		PAGES.CODY_CROSS,
		PAGES.WORD_SWIPE_CHEATS,
		PAGES.WORDLE,
		PAGES.QUORDLE,
		PAGES.WORD_WARS,
	].includes(routeName);
};

export const isResultsPage = (routeName) => {
	return [
		PAGES.UNSCRAMBLER_RESULTS,
		PAGES.SCRABBLE_DICT_RESULTS,
		PAGES.ANAGRAM_RESULTS,
		PAGES.WORDS_STARTING_RESULTS,
		PAGES.WORDS_HAVING_RESULTS,
		PAGES.WORDS_ENDING_RESULTS,
		PAGES.WORDS_BY_LENGTH_RESULTS,
		PAGES.WORDS_CONSONANTS,
		PAGES.WORDS_VOWELS,
		PAGES.WORDS_ENDING_ING,
		PAGES.WORDS_WITH_Q_NO_U,
		PAGES.WORDS_COMBINATION_LETTERS_RESULTS,
		PAGES.WORDS_COMBINATION_LENGTH_AND_LETTERS_RESULTS,
		PAGES.WORDLE_RESULTS,
		PAGES.QUORDLE_RESULTS,
	].includes(routeName);
};

/**
 * Takes a route name and checks
 * if the page is a word list page.
 * Note: returns true for both result
 * AND non-result word list pages.
 * @param {String} routeName
 * @returns
 */
export const isWordListPage = (routeName) => {
	return [
		PAGES.WORDS_LISTS_INDEX,
		PAGES.WORDS_STARTING_INDEX,
		PAGES.WORDS_STARTING_RESULTS,
		PAGES.WORDS_HAVING_INDEX,
		PAGES.WORDS_HAVING_RESULTS,
		PAGES.WORDS_ENDING_INDEX,
		PAGES.WORDS_ENDING_RESULTS,
		PAGES.WORDS_BY_LENGTH_INDEX,
		PAGES.WORDS_BY_LENGTH_RESULTS,
		PAGES.WORDS_CONSONANTS,
		PAGES.WORDS_VOWELS,
		PAGES.WORDS_ENDING_ING,
		PAGES.WORDS_WITH_Q_NO_U,
		PAGES.WORDS_COMBINATION_LETTERS_RESULTS,
		PAGES.WORDS_COMBINATION_LENGTH_AND_LETTERS_RESULTS,
	].includes(routeName);
};

/**
 * Given a combined wordlist results route (e.g: /starts-a-with-c/, /3-ends-b/)
 * this function returns the value of the target advanced search parameter
 *
 * @param param - the advanced param from the router path
 * @param prefixString - the prefix identifying the target param (e.g: starts, with, ends). Null to return the length parameter.
 */
export const getCombinedWLPageQueryParam = (param, prefixString) => {
	if (isEmpty(param)) {
		return "";
	}

	if (!prefixString) {
		return param.replace(/\D/g, "");
	}

	const letterSubstring = param.split(`${prefixString}-`)[1] || "";

	return letterSubstring.includes("-")
		? letterSubstring.substring(0, letterSubstring.indexOf("-"))
		: letterSubstring;
};

/**
 * Create a canonical URL from Vue router's current route
 * @param {Object} route - The Vue Router's current route object
 * @param {Boolean} includeQuery - True if to include the routes params in the result. False otherwise.
 * @return {Object} An object with the canonical URL with fixed protocol and trailing slash
 */
export const canonical = (route, includeQuery = false) => {
	const map = Array.prototype.map;
	let path = map.call(route.path, (letter) => letter.toLowerCase()).join("");

	// Fix missing leading slash in relative path
	path = path.charAt(0) !== "/" ? `/${path}` : path;

	// Sanitize tiles for results
	/* eslint-disable-next-line no-useless-escape */
	path = /\/([^\/]+)\/([^\/]+)\/?$/.test(path)
		? /* eslint-disable-next-line no-useless-escape */
		  path.replace(/\/([^\/]+)\/([^\/]+)\/?$/, (match, p1, p2) => {
				if (["unscramble", "scrabble-dictionary"].includes(p1)) {
					return `/${p1}/${sanitizeTiles(p2)}`;
				} else {
					return `/${p1}/${p2}`;
				}
		  })
		: path;

	let url = `${PRODUCTION_ORIGIN}${path}`;
	// All canonical urls must end with a "/"
	if (!url.endsWith("/")) {
		url = `${url}/`;
	}

	url =
		includeQuery && route.query && Object.keys(route.query).length
			? `${url}?${new URLSearchParams(route.query).toString()}`
			: url;

	return {
		rel: "canonical",
		href: url,
	};
};

/**
 * Takes a data object (all parameters are optional), and returns a string that combines all keys and values from the data object (filtering out empty values of course), formatted for a URL e.g. "starts=x&ends=y". For easier comparison, the params are always in alphabetical order.
 *
 * @param {Object} data
 * @param {Boolean} returnAsObject If true, returns as an object instead. Duh.
 *
 * @returns {String | Object}
 */
export const buildResultsQueryParams = (data, returnAsObject = false) => {
	return returnAsObject
		? data
		: Object.entries(data)
				.map(([key, value]) => {
					if (isEmpty(value)) {
						return key;
					}
					return `${key}=${value}`;
				})
				.join("&");
};

/**
 * Takes a path and alphabetizes the query parameters.
 *
 * @param {String} url
 * @returns {String} the sorted url
 */
export const sortUrl = (url) => {
	url = typeof url === "string" ? url : "";
	const [main, query] = url.split("?");
	const params = new URLSearchParams(query);
	params.sort();
	if (params.toString().length) {
		return [main, params.toString()].join("?");
	}

	return main;
};

export const flattenQueryParams = (queryObj) =>
	Object.entries(queryObj)
		.sort(([aKey], [bKey]) => {
			aKey = aKey?.toLowerCase?.() || "";
			bKey = bKey?.toLowerCase?.() || "";

			if (aKey < bKey) {
				return -1;
			} else if (bKey < aKey) {
				return 1;
			} else {
				return 0;
			}
		})
		.map(([key, val]) => `${key}=${val}`)
		.join("&");

/**
 * @typedef {Object} RouteDataParams
 * @property {String} [tiles]
 * @property {String} fullPath
 * @property {Object} advanced Params related to advanced search fields (or word lists)
 * @property {String} [advanced.starts]
 * @property {String} [advanced.contains]
 * @property {String} [advanced.ends]
 * @property {Number} [advanced.length]
 * @property {Object} wordle Params related to wordle/quordle
 * @property {String} [wordle.correct]
 * @property {String} [wordle.includes]
 * @property {String} [wordle.excludes]
 * @property {Object} misc Misc params that cannot be grouped anywhere else but are needed for API calls
 *
 *
 * @typedef {Object} RouteData
 * @property {RouteDataParams} raw The raw params/query values from the URL
 * @property {RouteDataParams} clean The cleaned params/query values from the URL
 */

/**
 * Takes a single route object, and extracts all available params and queries from it. Also cleans up the params and queries, and returns the corrected URL if
 *
 * @param {WF_Common.RouteObject} route The route object
 * @returns {RouteData} The parsed object for this route.
 */
export const getSearchParamsFromRoute = (route) => {
	/** @type {RouteData} */
	const data = {
		raw: { advanced: {}, wordle: {}, misc: {} },
		clean: { advanced: {}, wordle: {}, misc: {} },
	};

	data.raw.fullPath = route.fullPath;

	let tiles = route.params?.tiles || "";
	if (tiles) {
		data.raw.tiles = tiles;

		tiles = tiles.toLowerCase();
		const sanitisationRegex =
			route.name === PAGES.UNSCRAMBLER_RESULTS ? /[a-z_]/i : /[a-z]/i;
		tiles = sanitizeTiles(tiles, true, false, sanitisationRegex);
		if (!isEmpty(tiles)) {
			data.clean.tiles = tiles;
		}
	}

	const paramKeyMap = {
		starts: "starts",
		contains: "with",
		ends: "ends",
	};

	// This handles words starting results (e.g. /words-that-start/a/), words containing results (e.g. /words-with-the-letter/a/), and words ending results (e.g. /ending-with/a/)
	let letter = route.params?.letter || "";
	if (letter) {
		let paramKey;
		let isContains = false;
		switch (route.name) {
			case PAGES.WORDS_STARTING_RESULTS:
				paramKey = Object.keys(paramKeyMap)[0];
				break;
			case PAGES.WORDS_HAVING_RESULTS:
				paramKey = Object.keys(paramKeyMap)[1];
				isContains = true;
				break;
			case PAGES.WORDS_ENDING_RESULTS:
				paramKey = Object.keys(paramKeyMap)[2];
				break;
		}

		if (paramKey) {
			data.raw.advanced[paramKey] = letter;
			const sanitisationRegex = isContains ? /[a-z_-]/i : /[a-z]/i;
			letter = letter.toLowerCase();
			letter = sanitizeTiles(letter, true, false, sanitisationRegex);

			// If this is a words-with-the-letter URL, and it has a hyphen, but it is NOT a x-and-y pattern, then the hyphen should not be there. Remove it.
			if (
				isContains &&
				letter.includes("-") &&
				!/^(\w+)-and-(\w+)$/.test(letter)
			) {
				letter = letter.replace(/-/g, "");
			}

			if (!isEmpty(letter)) {
				data.clean.advanced[paramKey] = letter;
			}
		}
	}

	if (route.name === PAGES.WORDS_ENDING_ING) {
		data.raw.advanced.ends = "ing";
		data.clean.advanced.ends = "ing";
	}

	// This is for `starts`, `contains`, and `ends` as query parameters (e.g. /unscramble/hello/?starts=a&contains=b&ends=c)
	Object.keys(paramKeyMap).forEach((paramKey) => {
		let rawValue = route.query?.[paramKey] || "";
		if (Array.isArray(rawValue)) {
			rawValue = rawValue[0];
		}

		if (rawValue) {
			data.raw.advanced[paramKey] = rawValue;

			/**
			 * @note be careful about the regex here (and below) as opposed to the earlier declaraction of `sanitisationRegex` above, especially the presence of the hyphen `-` character. This is because the previous scenario needs to be able to support URLs like `/words-with-the-letter/x-and-y/` (where the `letter` param accepts hyphens) whereas this scenario is for stuff like `/words-with-the-letter/starts-x-ends-y/`, where the `with` subvalue (in this case `x`) of the `advanced` param cannot accept hyphens.
			 */
			const sanitisationRegex =
				paramKey === "contains" ? /[a-z_]/i : /[a-z]/i;
			let cleanValue = rawValue.toLowerCase();
			cleanValue = sanitizeTiles(
				cleanValue,
				false,
				false,
				sanitisationRegex
			);
			if (!isEmpty(cleanValue)) {
				data.clean.advanced[paramKey] = cleanValue;
			}
		}
	});

	// This handles letter as either a param (e.g. /letter-words/6/) or a query (e.g. /unscramble/hello?length=6)
	let length = route.params?.length || route.query?.length || "";
	if (Array.isArray(length)) {
		length = length[0];
	}
	if (length) {
		data.raw.advanced.length = length;
		length = parseInt(length);
		if (!isNaN(length)) {
			length = clamp(
				length,
				WORD_LIST_LIMITS.LENGTH_MIN,
				WORD_LIST_LIMITS.LENGTH_MAX
			);
			data.clean.advanced.length = length;
		}
	}

	// This handles combination pages (both including and not including length)
	const advanced = route.params?.advanced || "";
	if (advanced) {
		Object.entries(paramKeyMap).forEach(([paramKey, urlKey]) => {
			const rawValue =
				getCombinedWLPageQueryParam(advanced, urlKey) || "";
			if (rawValue) {
				data.raw.advanced[paramKey] = rawValue;
				const sanitisationRegex =
					paramKey === "contains" ? /[a-z_]/i : /[a-z]/i;
				let cleanValue = rawValue.toLowerCase();
				cleanValue = sanitizeTiles(
					cleanValue,
					false,
					false,
					sanitisationRegex
				);
				if (!isEmpty(cleanValue)) {
					data.clean.advanced[paramKey] = cleanValue;
				}
			}
		});

		let length = getCombinedWLPageQueryParam(advanced);
		if (length) {
			data.raw.advanced.length = length;
			length = parseInt(length);
			if (!isNaN(length)) {
				length = clamp(
					length,
					WORD_LIST_LIMITS.LENGTH_MIN,
					WORD_LIST_LIMITS.LENGTH_MAX
				);
				data.clean.advanced.length = length;
			}
		}
	}

	// This handles the 3 parameters related to wordle and quordle
	const wordleParamKeys = ["correct", "includes", "excludes"];
	wordleParamKeys.forEach((paramKey, i) => {
		let rawValue = route.query?.[paramKey] || "";
		if (Array.isArray(rawValue)) {
			rawValue = rawValue[0];
		}
		if (!rawValue) {
			return;
		}
		data.raw.wordle[paramKey] = rawValue;

		let cleanValue;
		if (i === 0) {
			// correct
			cleanValue = rawValue.toLowerCase().replace(/[^a-z_]/g, "");
			cleanValue = cleanValue.substring(0, 5);
		} else if (i === 1) {
			// includes
			cleanValue = rawValue.toLowerCase().replace(/[^a-z]/g, "");
			cleanValue = cleanValue.substring(0, 5);
		} else if (i === 2) {
			// excludes
			cleanValue = rawValue.toLowerCase().replace(/[^a-z]/g, "");
			cleanValue = Array.from(new Set(cleanValue)).join("");
			const includesVal = data.clean?.wordle?.includes || "";
			const includesReg = new RegExp(
				includesVal.split("").join("|"),
				"g"
			);
			cleanValue = cleanValue.replace(includesReg, "");
			cleanValue = cleanValue.substring(0, 25);
		}

		if (!isEmpty(cleanValue)) {
			data.clean.wordle[paramKey] = cleanValue;
		}
	});

	let cleanUrl = "";
	switch (route.name) {
		case PAGES.UNSCRAMBLER_RESULTS:
			cleanUrl = getResultsUrl(data.clean.tiles, SEARCH_GAMES.UNSCRAMBLE);
			break;
		case PAGES.SCRABBLE_DICT_RESULTS:
			cleanUrl = getResultsUrl(data.clean.tiles, SEARCH_GAMES.SCRABBLE);
			break;
		case PAGES.ANAGRAM_RESULTS:
			cleanUrl = getResultsUrl(data.clean.tiles, SEARCH_GAMES.ANAGRAM);
			break;
		case PAGES.WORDLE_RESULTS:
			cleanUrl = getResultsUrl(
				"",
				SEARCH_GAMES.WORDLE,
				data.clean.wordle
			);
			break;
		case PAGES.QUORDLE_RESULTS:
			cleanUrl = getResultsUrl(
				"",
				SEARCH_GAMES.QUORDLE,
				data.clean.wordle
			);
			break;
		case PAGES.WORDS_STARTING_RESULTS:
		case PAGES.WORDS_HAVING_RESULTS:
		case PAGES.WORDS_ENDING_RESULTS:
		case PAGES.WORDS_BY_LENGTH_RESULTS:
		case PAGES.WORDS_COMBINATION_LENGTH_AND_LETTERS_RESULTS:
		case PAGES.WORDS_COMBINATION_LETTERS_RESULTS:
		case PAGES.WORDS_ENDING_ING:
			cleanUrl = buildWordListPathFromFields(
				data.clean.advanced.length,
				data.clean.advanced.starts,
				data.clean.advanced.contains,
				data.clean.advanced.ends
			);
			break;
		default:
			cleanUrl = data.raw.fullPath;
			break;
	}

	// We also want to capture any query params that haven't been captured by the earlier groups, such as bucket toggles.
	const existingKeys = [
		"starts",
		"contains",
		"ends",
		"length",
		"correct",
		"includes",
		"excludes",
	];

	Object.entries(route.query).forEach(([key, value]) => {
		if (!existingKeys.includes(key)) {
			data.raw.misc[key] = value;
			/**
			 * @todo this is a good place to clean up invalid dictionary / page values.
			 */

			/**
			 * @todo these sort options exist as a SORT_OPTIONS const in the TableController component. Extract that into the consts folder and DRY this up.
			 */
			if (
				key === "order_by" &&
				!["score", "alpha", "-alpha"].includes(value)
			) {
				value = null;
			}
			if (!(["order_by", "dictionary"].includes(key) && isEmpty(value))) {
				data.clean.misc[key] = value;
			}
		}
	});

	const cleanQueryParams = buildResultsQueryParams({
		// Only add the advanced query params if the route is the Unscramble results page.
		...(route.name === PAGES.UNSCRAMBLER_RESULTS
			? data.clean.advanced
			: {}),
		...data.clean.misc,
	});

	// If `cleanUrl` already has query params (e.g. from Wordle) then we want to append instead.
	const queryParamConnector = cleanUrl.includes("?") ? "&" : "?";

	data.clean.fullPath = `${cleanUrl}${
		cleanQueryParams ? `${queryParamConnector}${cleanQueryParams}` : ""
	}`;

	return data;
};

export const newsArticleSchema = (schemaInfo) => {
	return {
		hid: "jsonld-article-schema",
		type: "application/ld+json",
		json: {
			"@context": "https://schema.org",
			"@type": "NewsArticle",
			headline: schemaInfo.headline,
			image: [schemaInfo.image],

			datePublished: schemaInfo.datePublished,
			dateModified: `${dayjs.tz(new Date(), "UTC").toISOString()}`,
			author: [
				{
					"@type": "Organization",
					name: "WordFinder team",
					url: "https://wordfinder.yourdictionary.com/about/",
				},
			],
		},
	};
};
